import React  from 'react'
import { StaticQuery, graphql } from 'gatsby'
// import ReactDOM from 'react-dom'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import github from '../img/github-icon.svg'
// import logo from '../img/logo.svg'
import NavMenuCustomModalSr from './NavMenuCustomModalSr'
import archOptBlack from '../img/arch_opt_black.svg'
// import archOptWhite from '../img/arch_opt_white.svg'

const NavbarSr = () => (
  <StaticQuery
    query={graphql`
      query {
        allWpPage(
          sort: { fields: id }
          limit: 5
		  filter: { locale: { locale: { eq: "sr_RS" } } }
        ) {
          edges {
            node {
              title
              slug
			  locale{
				locale
			  }
            }
          }
        }
      }
    `}
   render={(data) => (
      <div className="top-bar navigationElementHolder header sr_ver">
        <div className="logo">
          <AniLink fade direction="up" bg="#fff" duration={1} to="/sr/">
            <figure className="image">
              <img src={archOptBlack} alt="Ana Nenadovic" />
            </figure>
          </AniLink>
        </div>
        <div>
          <NavMenuCustomModalSr class="sr_ver" />
        </div>
      </div>
    )}
  />
)

export default NavbarSr
